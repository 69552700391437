import React from 'react';
import { ScComponent } from '@/types/interfaces';

export enum InputTypes {
  Text = 'text',
  Password = 'password',
  Email = 'email',
  Phone = 'phone',
  Number = 'number',
}

export interface InputProps
  extends ScComponent,
    Pick<
      React.ComponentProps<'input'>,
      | 'onFocus'
      | 'onBlur'
      | 'name'
      | 'placeholder'
      | 'disabled'
      | 'autoComplete'
      | 'type'
    > {
  value?: string;
  onChange: (val: string) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  step?: number;
  float?: number;
  onClick?: () => void;
  id?: string;
}

const Input: React.FC<InputProps> = ({
  className = '',
  id = '',
  type = InputTypes.Text,
  onChange = () => {},
  onPaste,
  step,
  float = -1,
  onClick = () => {},

  ...props
}) => {
  return (
    <input
      onClick={onClick}
      className={`input ${className}`}
      type={type}
      onChange={(evt) => {
        if (float > -1 && type === InputTypes.Number) {
          const value = Number(evt.currentTarget.value).toFixed(float);
          onChange(value);
        } else {
          onChange(evt.currentTarget.value);
        }
      }}
      onPaste={onPaste}
      step={step}
      id={id}
      {...props}
    />
  );
};

export default Input;
