import {
  DropdownMenuGroupType,
  DropdownMenuOptionType,
  isDropdownMenuGroups,
} from '@/types';
import { ScComponent } from '@/types/interfaces';
import React, { useMemo } from 'react';
import DropdownMenuItem, {
  DropdownMenuItemVariants,
} from '@/components/molecules/DropdownMenuItem';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import Button, { ButtonVariants } from '@/components/atoms/Button/Button';

export interface DropdownMenuProps extends ScComponent {
  open?: boolean;
  variant?: DropdownMenuItemVariants;
  options: DropdownMenuOptionType[] | DropdownMenuGroupType[];
  value: string[];
  onChange: (value: string[]) => void;
  setValueIndex?: (value: number) => void;
  showCount?: boolean;
  clearAll?: () => void;
  apply?: () => void;
  noOptions?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  className = '',
  variant = DropdownMenuItemVariants.SingleSelect,
  options,
  noOptions = false,
  value,
  onChange,
  showCount = true,
  clearAll,
  apply,
}) => {
  const sortedCategories: DropdownMenuGroupType[] | null = useMemo(() => {
    if (isDropdownMenuGroups(options)) {
      return options.sort((a, b) => a.index - b.index);
    }
    return null;
  }, [options]);

  const containerClassName = `h-content z-20 flex flex-col justify-between max-h-[300px] rounded-small bg-neutral-0 shadow-md ${className}`;

  return (
    <div className={containerClassName}>
      <div className="modal-scroll-bar w-full overflow-auto p-s">
        {noOptions && (
          <div className="flex flex-wrap items-center text-microMedium text-red-medium">
            <Icon
              iconId={IconIds.Danger}
              strokeColor="#B00C0C"
              className="mr-xs"
            />
            No results found
          </div>
        )}

        {!noOptions &&
          sortedCategories &&
          sortedCategories.map((categoryObj, catIndex) =>
            categoryObj?.menuItems?.length > 0 ? (
              <div key={`${categoryObj?.category || ''}-${catIndex}`}>
                <span className="block py-s text-microMedium text-neutral-600">
                  {categoryObj.category}
                </span>
                {categoryObj.menuItems.map((option, index) => (
                  <DropdownMenuItem
                    key={`dropdown-${option.value}-${index}`}
                    text={option.text || ''}
                    variant={variant}
                    selected={value.includes(option.value)}
                    count={option.count}
                    showCount={showCount}
                    onChange={() => {
                      if (
                        variant === DropdownMenuItemVariants.SingleSelect ||
                        variant === DropdownMenuItemVariants.NoSelect
                      ) {
                        onChange([option.value]);
                      } else if (value.includes(option.value)) {
                        onChange(value.filter((v) => v !== option.value));
                      } else {
                        onChange([...value, option.value]);
                      }
                    }}
                    makeIcon={option.makeIcon}
                  />
                ))}
              </div>
            ) : null
          )}

        {!noOptions &&
          (!sortedCategories || sortedCategories.length === 0) &&
          options.map((option, index) => {
            const dropdownOption = option as DropdownMenuOptionType;
            return (
              <DropdownMenuItem
                key={`dropdown-${dropdownOption.value}-${index}`}
                text={dropdownOption.text || ''}
                variant={variant}
                selected={value.includes(dropdownOption.value)}
                count={dropdownOption.count}
                showCount={showCount}
                onChange={() => {
                  if (
                    variant === DropdownMenuItemVariants.SingleSelect ||
                    variant === DropdownMenuItemVariants.NoSelect
                  ) {
                    onChange([dropdownOption.value]);
                  } else if (value.includes(dropdownOption.value)) {
                    onChange(value.filter((v) => v !== dropdownOption.value));
                  } else {
                    onChange([...value, dropdownOption.value]);
                  }
                }}
                makeIcon={dropdownOption.makeIcon}
              />
            );
          })}
      </div>
      {!noOptions && clearAll && (
        <div className="align-start flex items-end justify-center gap-s border-t-2 border-neutral-200 px-l py-s">
          <div className="flex-grow">
            <Button
              aria-label="Clear all options"
              variant={ButtonVariants.Secondary}
              onClick={clearAll}
            >
              Clear all
            </Button>
          </div>
          <div className="flex-grow">
            <Button
              aria-label="Apply options"
              variant={ButtonVariants.Primary}
              onClick={apply}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
