import React from 'react';
import NextImage from 'next/image';
import { S3_OEM_LOGO_LINK } from '@/lib/constants';
import { MakeIconProps } from '@/types';

export const MakeIcon: React.FC<MakeIconProps> = ({
  make,
  className = '',
  width = 16,
  height = 16,
  'aria-label': ariaLabel,
}: MakeIconProps) => {
  const [error, setError] = React.useState(false);
  const src = `${S3_OEM_LOGO_LINK}/${make}.png`;

  const handleImageError = () => {
    setError(true);
  };
  return error ? null : (
    <div className={className}>
      <NextImage
        aria-label={ariaLabel || `${make} icon`}
        src={src}
        alt={`${make} icon`}
        onError={handleImageError}
        width={width}
        height={height}
      />
    </div>
  );
};
